<template>
    <a @click="open()" class="btn btn-danger">
        {{ $t('mywallet.modal.block-token.open') }}
    </a>

    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-wallettoken-block fixed-modal-wrapper" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div class="modal-content">
                        <div class="modal-close">
                            <a @click="close()"
                               class="cursor-pointer text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                               aria-label="Close"
                            >
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div class="">
                            <div class="text-center sm:text-left py-8 px-4  sm:rounded-sm sm:px-10 pb-0">
                                <h2 class="text-center text-3xl font-extrabold">
                                    {{ $t('mywallet.modal.block-token.title') }}
                                </h2>
                                <!-- <p class="mt-2 text-center text-sm max-w">
                                    {{ $t('mywallet.modal.block-token.description') }}
                                </p> -->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer p-4">
                        <div class="button-flexer">
                            <button @click="block()" :disabled="saving"
                                    class="btn btn-confirm"
                            >
                                {{ $t('mywallet.modal.block-token.button') }}
                                <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"
                                    ></circle>
                                    <path class="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </button>
                            <button @click="close()"
                                    class="btn btn-close"
                            >
                                {{ $t('button.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
import * as _api from '../lib/api';
import Blur from "./Blur";

export default {
    props: [
        'wallettoken_id',
    ],
    components: {
        Blur,
    },
    data () {
        return {
            show: false,
            failed: false,
            success: false,
            saving: false,
            form: {
                barcode: '',
                pincode: '',
            },
        };
    },
    methods: {
        open () {
            this.show = true;
        },

        close () {
            this.show = false;
        },

        async block () {
            this.saving = true;

            const response = await _api.post('customer/wallettokens/block', {
                wallettoken_id: this.wallettoken_id,
            }, {}, false);

            if (response.result === 'success') {
                this.$emit('blocked');
            }

            this.saving = false;
        },
    },
};
</script>
