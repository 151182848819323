<template>
    <div class="flex flex-col justify-center sm:px-6 lg:px-8 ccss--user-wallet">
        <div class="mx-auto w-full max-w-xl">
            <h2 class="text-center text-3xl font-extrabold">
                {{ $t('mywallet.title') }}
            </h2>
        </div>
        <div class="mt-8">

            <div class="mx-auto w-full max-w-xl">
                <router-link :to="{ name: 'user.myaccount' }">
                    <a class="link-arrow-left">{{ $t('myaccount') }}</a>
                </router-link>
                <div v-for="(wallet, wallet_index) in wallets" :key="wallet_index"
                     class="content-container checkout-box radius-anchor wallets-container"
                >
                    <h2>
                        {{ wallet.attributes.description }}
                    </h2>
                    <!-- balances -->
                    <div class="wallets">
                        <div class="wallet radius-anchor" v-for="(balance, balance_index) in wallet.attributes.balances"
                             :key="balance_index"
                        >
                            <h3>{{ balance.type }}: {{ balance.balance }}</h3>
                            <!--                        <modal-wallet-mutations :balance="balance.balance" :type="balance.type"-->
                            <!--                                                :wallet="wallet.attributes.description" />-->
                        </div>

                    </div>

                    <!-- tokens -->
                    <div class="tokens">
                        <div class="flexed-header">
                            <h2 class="">{{ $t('mywallet.tokens') }}</h2>
                            <div class="flex flex-wrap items-center justify-between sm:justify-end mb-2">
                                <!--                            <div class="mr-4" >-->
                                <!--                                <modal-wallettoken-activate @activated="userWallets()" />-->
                                <!--                            </div>-->
                                <modal-wallettoken-connect @activated="userWallets()" :wallet_id="wallet.id" />
                            </div>
                        </div>

                        <div class="tokens-list radius-anchor"
                             v-if="wallet.attributes.tokens && wallet.attributes.tokens.length"
                        >
                            <div v-for="(token, token_index) in wallet.attributes.tokens"
                                 :key="token_index" class="token" :class="{'danger': token.blocked}"
                            >
                                <div>{{ token.cardnumber }}</div>
                                <div v-if="token.blocked" class="inline-flex">
                                    Blocked
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" class="ml-2"
                                    >
                                        <path
                                            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                            stroke="#E50000" stroke-width="2" stroke-miterlimit="10"
                                        />
                                        <path d="M14 8L8 14" stroke="#E50000" stroke-width="2" stroke-linecap="round"
                                              stroke-linejoin="round"
                                        />
                                        <path d="M8 8L14 14" stroke="#E50000" stroke-width="2" stroke-linecap="round"
                                              stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div v-else>
                                    <modal-wallettoken-block :wallettoken_id="token.id" @blocked="userWallets()" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-end pt-4"
                         v-if="wallet.attributes.tokens && wallet.attributes.tokens.length"
                    >
                        <router-link :to="{ path: '/opwaarderen/wallet/opwaarderen'}">
                            {{ $t("navigation.walletorder") }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as _api from '../lib/api';

// modals
// import ModalWallettokenActivate from '../components/ModalWallettokenActivate.vue';
import ModalWallettokenConnect from '../components/ModalWallettokenConnect.vue';
import ModalWallettokenBlock from '../components/ModalWallettokenBlock.vue';
// import ModalWalletMutations from '../components/ModalWalletMutations.vue';

export default {
    name: 'UserWallet',
    components: {
        // ModalWallettokenActivate,
        ModalWallettokenConnect,
        ModalWallettokenBlock,
        // ModalWalletMutations,
    },
    data () {

        return {
            wallets: [],
        };
    },
    async mounted () {
        this.init();
    },

    methods: {
        async init () {
            await this.userWallets();
        },

        async userWallets () {
            this.wallets = await _api.get('customer/wallets', {}, false);
        },
    },
};
</script>
