<template>
    <a @click="open()" class="btn btn-primary font-bold">
        {{ $t('mywallet.modal.connect-token.open') }}
    </a>

    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-wallettoken-connect fixed-modal-wrapper" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

                <div class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="">
                        <div class="modal-close">
                            <a @click="close()" class="cursor-pointer text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </a>
                        </div>
                        <div class="mb-4">
                            <div class="text-center sm:text-left py-8 px-4  sm:rounded-sm sm:px-10 pb-0">
                                <h2 class="text-center text-3xl font-extrabold">
                                    {{ $t('mywallet.modal.connect-token.title') }}
                                </h2>
                                <p class="mt-2 text-center text-base max-w">
                                    {{ $t('mywallet.modal.connect-token.description') }}
                                </p>
                            </div>
                        </div>

                        <div class="">
                            <div class="p-4">
                                <div class="">
                                    <form class="" @submit.prevent="submit" v-if="status !== 'success'">
                                        <div class="mb-2">
                                                <label for="cardnumber" class="block text-sm font-medium text-gray-700">
                                                    {{ $t('fields.cardnumber') }}
                                                </label>
                                                <div class="mt-1">
                                                    <input v-model="form.cardnumber" @focus="failed = false" id="cardnumber" name="cardnumber" type="text" autocomplete="cardnumber" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                                                </div>
                                            </div>
                                        <div class="p-4 flex">

                                            <div class="flex-grow mr-4 mb-2">
                                                <label for="cardnumber" class="block text-sm font-medium text-gray-700">
                                                    {{ $t('fields.cardnumber') }}
                                                </label>
                                                <div class="mt-1">
                                                    <input v-model="form.cardnumber" @focus="failed = false" id="cardnumber" name="cardnumber" type="text" autocomplete="cardnumber" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                                                </div>
                                            </div>
                                            <div class="w-32">
                                                <label for="pincode" class="block text-sm font-medium text-gray-700">
                                                    {{ $t('fields.pincode') }}
                                                </label>
                                                <div class="mt-1">
                                                    <input v-model="form.pincode" @focus="failed = false" id="pincode" name="pincode" type="pincode" autocomplete="current-pincode" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button type="submit" :disabled="saving" class="w-full flex justify-center py-3 px-4 btn btn-primary rounded-sm shadow-sm text-sm font-medium">
                                                {{ $t('mywallet.modal.connect-token.button') }}
                                                <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                <svg v-else class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                                    <path fill-rule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z" clip-rule="evenodd"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </form>

                                    <div v-else>
                                        <button @click="close()" class="w-full btn btn-primary">
                                            {{ $t('button.close') }}
                                        </button>
                                    </div>

                                    <div v-if="status === 'failed'" class="message-failed">
                                        {{ $t('mywallet.modal.connect-token.failed') }}
                                        {{ status_message }}
                                    </div>
                                    <div v-if="status === 'success'" class="message-success">
                                        {{ $t('mywallet.modal.connect-token.success') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import * as _api from '../lib/api';

import Blur from "./Blur";

export default {
    props: ['wallet_id'],
    components: {
        Blur,
    },
    data() {
        return {
            show: false,
            status: false,
            status_message: false,
            saving: false,
            form: {
                walletcode: '',
                cardnumber: '',
                pincode: '',
            }
        }
    },
    methods: {
        open() {
            this.reset()
            this.show = true
        },

        close() {
            this.reset()
            this.show = false
        },

        reset() {
            this.status = false;
            this.status_message = false;
            this.saving = false;
            this.form = {
                cardnumber: '',
                pincode: '',
            };
        },

        async submit () {
            this.status = false;
            this.status_message = false;
            this.saving = true;

            const data = {
                cardnumber: this.form.cardnumber,
                pincode: this.form.pincode,
                wallet_id: this.wallet_id,
            };

            const response = await _api.post('customer/wallettokens/connect', data, {}, false);

            this.status = response.result;
            this.status_message = response.message;

            this.saving = false;

            if (this.status === 'success') {
                this.$emit('activated');
            }
        },
    },
}
</script>
